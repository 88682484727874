import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const SchuldenErben = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Schulden erben – Die wichtigsten Antworten um vererbte Schulden."
                showCalc={false}
            />
            <Article>
                <p>
                    Du hast den Verdacht, dass du Schulden erben könntest? Oder weißt du bereits, wie hoch die Schulden
                    sind, die du erben wirst? Dann solltest du nicht zögern und dich gleich darüber informieren. Denn
                    wenn du rechtzeitig planst und objektiv analysiert, muss das Thema Schulden erben nicht immer eine
                    schlechte Nachricht für deine persönlichen Finanzen sein. Vor allem dann, wenn die Schulden mit
                    einem laufenden{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Immobilienkredit
                    </Link>{" "}
                    in Verbindung stehen. Einige der wichtigsten Antworten rund um das Thema geerbte Schulden wollen wir
                    in diesem Artikel für dich beantworten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Muss ich Schulden wirklich erben?</h2>
                <p>
                    Für viele Menschen ist Erben mit schönen Vorstellungen verbunden: Endlich genug Geld für lange
                    Reisen, eine eigene Wohnung und ein neues Auto haben. Doch in vielen Fällen ist mit einem Erbe auch
                    eine finanzielle Last verbunden. Denn nicht nur Vermögen können vererbt werden, sondern eben auch
                    Schulden.
                </p>
                <p>
                    In Österreich und auch anderen europäischen Ländern kann man entweder das gesamte Erbe antreten oder
                    auf das gesamte Erbe verzichten. Wenn also die Schulden den größeren Teil des Nachlasses ausmachen,
                    kannst du dir auch überlegen, ob du auf dein Erbe verzichten willst. In der Regel hast du dafür
                    einige Wochen lang Zeit – sprich aber unbedingt immer mit einem Finanzexperten oder zum Beispiel
                    einem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        unabhängigen Berater
                    </Link>{" "}
                    deines Vertrauens, wenn du feststellst, dass dein Erbe mit Schulden belastet sein könnte.
                </p>
                <hr />

                <h2>Nachlass genau prüfen</h2>
                <p>
                    Viele Menschen begehen den Fehler, einen Nachlass ohne professionelle Prüfung zu übernehmen. Sie
                    wissen also nicht, ob ihre finanziellen Mittel überhaupt ausreichen, um auch die Schulden zu
                    begleichen, die vererbt werden. Entweder man überprüft sie nicht genau, oder man versäumt die Frist.
                    Diese Frist dauert meist sechs Wochen und beginnt an dem Tag, an dem du als gesetzlicher Erbe oder
                    Erbin in Kenntnis gesetzt wirst. Zum Beispiel an dem Tag, an dem die Testamentseröffnung vor Gericht
                    stattfindet.
                </p>
                <p>
                    Achtung: Sobald du die Frist von sechs Wochen versäumst, bist du verpflichtet, mit deinem
                    persönlichen Vermögen für die Schulden zu haften.
                </p>
                <hr />

                <h2>Schulden erben ohne Benachrichtigung</h2>
                <p>
                    Es kann vorkommen, dass du gar nicht innerhalb der 6-wöchigen Frist über dein Erbe informiert wirst.
                    Das ist zum Beispiel immer dann der Fall, wenn du die gesetzliche Erbfolge antrittst. Bist du also
                    der nächste Erbe, bekommst du keine amtliche Benachrichtigung über deine Erbschaft zugeschickt.
                </p>
                <p>
                    Du wirst im Umkehrschluss nur über ein Erbe benachrichtigt, wenn es eine letztwillige Verfügung gibt
                    oder du in der Erbfolge nachrückst. Auch wenn es ein Testament oder einen Erbvertrag gibt, kannst du
                    ab dem Eröffnen der Verfügung das Erbe ausschlagen. Das heißt, du kannst entscheiden, ob du das Erbe
                    wirklich annehmen möchtest oder nicht.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Schulden erben – Chancen erkennen</h2>
                <p>
                    Wenn man feststellt, dass man Schulden erben könnte, sollte man aber zuallererst nicht in Panik
                    geraten. Denn oft sind Schulden nur auf den ersten Blick ein echtes Problem. Stell dir zum Beispiel
                    vor, du erst eine Immobilie, die noch mit einigen Hunderttausend Euro Schulden belastet ist. Du
                    könntest im ersten Moment denken, dass du diese finanzielle Last nicht packen kannst.
                </p>
                <p>
                    Gleichzeitig solltest du aber daran denken, dass Immobilien auch eine Form von Kapital sind und
                    einen Wert haben, der ganz unabhängig von der eventuell noch ausstehenden Kreditsumme besteht. Wenn
                    du also eine Immobilie erbst, deren Wert höher ist als die Summer der Schulden, hast du langfristig
                    nicht zu befürchten.
                </p>
                <p>
                    Merke: Vergleiche immer die Summe der geerbten Schulden mit dem gesamten und potenziellen Wert
                    deines Erbes!
                </p>
                <hr />

                <h2>Was du tun solltest, wenn du befürchtest, dass du Schulden erben könntest</h2>
                <p>
                    Wie du dich auch entschiedest: Bevor du ein Erbe annimmst oder ausschlägst, solltest du unbedingt
                    einen Experten oder eine Expertin zurate ziehen.
                </p>
                <p>
                    In komplexeren Erbfragen ist sind Anwälte, die auf Erbrecht spezialisiert sind, sicher eine gute
                    Idee. Vor allem, wenn der Nachlass sehr groß und unübersichtlich ist, kann dir diese Berater*innen
                    helfen, den Überblick zu bekommen und zu bewahren.
                </p>
                <hr />

                <h2>Immobilie erben mit Schulden</h2>
                <p>
                    Wenn du eine Immobilie erbst, die zum Teil noch mit Schulden – z.B. in Form eines nicht ganz zu Ende
                    bezahlten Kredits – belastet ist, kann es natürlich auch hilfreich sein, mit einem Finanzexperten zu
                    sprechen, der sich auf Immobilien spezialisiert hat – oder eventuell sogar auf das Thema Umschuldung
                    und Schulden erben. In diesem Fall kannst du dich natürlich auch an eine:n unserer{" "}
                    <Link to={urls.survey} target="_blank" rel="noreferrer noopener">
                        miracl-Berater:innen
                    </Link>{" "}
                    wenden, die dir kostenlos auch beim Thema geerbte Immobilie in Österreich weiterhelfen.
                </p>
                <p>
                    Abschließend bleibt zu sagen, dass das ein Erbe zwar einen großen Unterschied in deinem Leben machen
                    kann – und zwar zum positiven wie zum negativen. Ob man ein Erbe annimmt oder nicht, sollte aber
                    immer gut überlegt sein und in keinem Fall sind vererbte Schulden immer eine schlechte Nachricht.
                    Mit der richtigen Beratung und einem guten finanziellen Überblick können sich mittel- und
                    langfristig auch geerbte Schulden zu einem echten Asset verwandeln lassen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"SchuldenErben"}></BreadcrumbList>
            <ArticleStructuredData
                page={"SchuldenErben"}
                heading={"Schulden erben – Die wichtigsten Antworten um vererbte Schulden."}
            />
        </Layout>
    );
};

export default SchuldenErben;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.schulden-erben", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
